<template>
<!-- 代金券基本信息 -->
  <div class="box100">
    <el-form class="settingInfoBox" :model="vouInfo" :rules="vouInfoRule" ref="vouInfoForm">
      <div class="basicClass">
        <p class="formTitle">基本信息</p>
        <div class="flexCB">
          <el-form-item label="代金券名称" class="detailFormItem" prop="mkname">
            <el-input v-model="vouInfo.mkname"  placeholder="请输入代金券名称" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="代金券类型" class="detailFormItem fiLabelLeft" prop="mktype">
            <el-radio :label="1" v-model="vouInfo.mktype">满减</el-radio>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="面值" class="detailFormItem" prop="mkprice">
            <el-input v-model="vouInfo.mkprice"  placeholder="请输入面值金额" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="渠道名称" class="detailFormItem" prop="mkchannel">
            <el-input v-model="vouInfo.mkchannel"  placeholder="请输入渠道名称" maxlength="20"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="消费金额" class="detailFormItem" prop="mkmaxprice">
            <el-input v-model="vouInfo.mkmaxprice"  placeholder="请输入消费金额" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="发放方式" class="detailFormItem" prop="mkpayway">
            <el-select v-model="vouInfo.mkpayway" placeholder="请选择发放方式" style="width: 100%;">
              <el-option v-for="item in mkstateList" :key="item.index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="是否可累积使用" prop="mkamassok" class="fiLabelLeft">
            <p><el-radio v-model="vouInfo.mkamassok" :label="1">是</el-radio></p>
            <div class="flexAC">
              <el-radio v-model="vouInfo.mkamassok" :label="2">否</el-radio>
              <el-form-item prop="mkamassro">
                <el-select v-model="vouInfo.mkamassro" placeholder="请选择限制规则" style="width: 160px;" :disabled="vouInfo.mkamassok != 2">
                  <el-option v-for="item in mkamassnoList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <span style="margin: 0 10px;">使用</span>
              <el-form-item prop="mkamassno">
                <el-input v-model="vouInfo.mkamassno" placeholder="请输入张数" style="width: 120px;" :disabled="vouInfo.mkamassok != 2"></el-input>
              </el-form-item>
              <span style="margin: 0 10px;">张</span>
            </div>
          </el-form-item>
          <el-form-item label="适用人群" class="detailFormItem fiLabelLeft" prop="mkdapt">
            <el-radio-group v-model="vouInfo.mkdapt">
              <el-radio :label="1">全部用户</el-radio>
              <el-radio :label="2">新用户</el-radio>
              <el-radio :label="3">老用户</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="内容描述" class="detailFormItem" prop="mkmark">
            <el-input v-model="vouInfo.mkmark" :rows="4" type="textarea" class="resizeNone" maxlength="200" placeholder="请输入内容描述(最多200字)"></el-input>
          </el-form-item>
          <el-form-item label="使用须知" class="detailFormItem" prop="mkinstruc">
            <el-input v-model="vouInfo.mkinstruc" :rows="4" type="textarea" class="resizeNone" maxlength="200" placeholder="请输入使用须知(最多200字)"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="有效期" prop="mkeffe" class="fiLabelLeft">
          <p><el-radio v-model="vouInfo.mkeffe" :label="1">永久</el-radio></p>
          <div class="flexAC">
            <el-radio v-model="vouInfo.mkeffe" :label="2">固定时段</el-radio>
            <el-date-picker
              v-model="vouInfo.time"
              type="daterange"
              style="width: 40%;"
              :disabled="vouInfo.mkeffe != 2"
              value-format="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <!-- <div class="flexAC formClass" style="margin-top:20px;">
            <el-radio v-model="vouInfo.mkeffe" :label="3">自购买起</el-radio>
            <el-form-item prop="mkdays">
              <el-input v-model="vouInfo.mkdays" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入时间" :disabled="vouInfo.mkeffe != 3" style="width: 150px;"></el-input>天
            </el-form-item>
          </div> -->
        </el-form-item>
        <el-form-item label="代金券数量" prop="mkcount" class="formTitle fiLabelLeft">
          <el-input v-model="vouInfo.mkcount"  placeholder="请输入生成数量"  class="zdyLabel"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <!--  提交按钮  -->
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
      <div class="settingCancel" @click="infoCancel">取消</div>
      <div class="settingConfirm" @click="infoConfirm">保存</div>
    </div>
  </div>
</template>

<script>
import { markkimAdd, markkimUpdate, markkimDetail } from '@/api/market.js'
export default {
  props: ['serveInfoEdit'],
  data () {
    const moneyReg = (rule,value,callback) => {
      if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
      }else{
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value){
            callback(new Error('请输入正整数或小数保留两位小数'));
          }else{
            callback()
          }
        }, 500);
      }
    };
    const numReg = (rule,value,callback) => {
      if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
      }else{
        setTimeout(() => {
          if (!/^[1-9]+[0-9]*$/.test(value) && value){
            callback(new Error('请输入正整数'));
          }else{
            callback()
          }
        }, 500);
      }
    };
    const numRegNo = (rule,value,callback) => {
      if (this.vouInfo.mkamassok === 2) {
        if (value === '') {
          callback(new Error('使用张数不能为空'));
        } else if (!/^[1-9]+[0-9]*$/.test(value) && value) {
          callback(new Error('请输入正整数'));
        }
      }
      callback()
    };
    const RegRo = (rule,value,callback) => {
      if (this.vouInfo.mkamassok === 2) {
        if (value === '') {
          callback(new Error('请选择使用规则'));
        }
      }
      callback()
    };
    return {
      vouInfo: {
        mkname: '',  //名称
        mktype: 1,   //满减
        mkprice: '', //面值
        mkcount: '',  //数量
        mkchannel: '',  //渠道名称
        mkmaxprice: '',  //消费金额
        mkpayway: 1,  //1平台发放  2线下发放
        mkamassok: 1,  //是否累积 1是 2否
        mkmark: '',  //内容
        mkinstruc: '',  //须知
        mkeffe: 1,  //有效期 1永久  2固定时间 3几天
        mkstime: '',  //开始时间
        mketime: '',  //结束时间
        mkdays: '',  //天数
        mkdapt: 1,  //适应人群
        time: [],
        mkamassro: '', //每人 / 每次
        mkamassno: '' //张数
      },
      vouInfoRule: {
        mkname: [
          { required: true, trigger: 'blur', message: '服务名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        mktype: { required: true, trigger: 'blur', message: '代金券类型不能为空' },
        mkprice: [
          { required: true, trigger: 'blur', message: '请输入面值金额' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mkchannel: { required: true, trigger: 'blur', message: '渠道名称不能为空' },
        mkmaxprice: [
          { required: true, trigger: 'blur', message: '请输入消费金额' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mkpayway: { required: true, trigger: 'blur', message: '请选择发放方式' },
        mkamassok: { required: true, trigger: 'blur', message: '请选择是否可累积' },
        mkdapt: { required: true, trigger: 'blur', message: '请选择适用人群' },
        mkmark: { required: true, trigger: 'blur', message: '请输入内容描述' },
        mkinstruc: { required: true, trigger: 'blur', message: '请输入使用须知' },
        mkeffe: { required: true, trigger: 'blur', message: '请选择有效期' },
        mkamassro: { required: true, trigger: 'blur', validator: RegRo },
        mkamassno: { required: true, trigger: 'blur', validator: numRegNo },
        mkcount: [{ required: true, trigger: 'blur', message: '请输入数量' },{ validator: numReg, trigger: 'blur' }],
        mkdays: [{ required: true, trigger: 'blur', message: '请输入天数' },{ validator: numReg, trigger: 'blur' }]
      },
      mkstateList: [{ label: '全部', value: 1 }, { label: '上架 ', value: 2 }, { label: '下架', value: 3 }],
      mkamassnoList: [{ label: '每人', value: '1' }, { label: '每次', value: '2' }]
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 查询
      if (this.serveInfoEdit !== null) {
        markkimDetail({ mkid: this.serveInfoEdit.mkid }).then(res => {
          if (res.status === 1) {
            this.vouInfo = res.data.data[0]
            if (this.vouInfo.mkeffe == 2) {
              this.vouInfo.time = [this.vouInfo.mkstime, this.vouInfo.mketime]
            }
          }
        })
      }
    },
    // 取消
    infoCancel () {
      this.$emit('HandCancel')
    },
    // 保存
    infoConfirm () {
      let _st = ''
      let _et = ''
      if (this.vouInfo.mkeffe === 2) {
        if (this.vouInfo.time.length == 0) {
          this.$message.warning('请选择时间')
          return
        } else {
          _st = this.vouInfo.time[0]
          _et = this.vouInfo.time[1]
        }
      }
     
			// if (this.vouInfo.mkamassok == 2) {
      //   if (this.vouInfo.mkamassro == '' || this.vouInfo.mkamassro == undefined ) {
      //     this.$message.warning('请选择限制规则') 
      //     return false
      //   } else if (this.vouInfo.mkamassno == '' || this.vouInfo.mkamassno == undefined ) {
      //     this.$message.warning('请输入张数')
      //     return false
      //   }
			// }
      this.$refs.vouInfoForm.validate((valid) => {
        if (!valid) return
        const params = {
          mkid: this.vouInfo.mkid,  //ID
          mkname: this.vouInfo.mkname,  //名称
          mktype: this.vouInfo.mktype,  //满减
          mkprice: this.vouInfo.mkprice,  //面值
          mkcount: this.vouInfo.mkcount,  //数量
          mkchannel: this.vouInfo.mkchannel,  //渠道名称
          mkmaxprice: this.vouInfo.mkmaxprice, //消费金额
          mkpayway: this.vouInfo.mkpayway,  //1平台发放  2线下发放
          mkamassok: this.vouInfo.mkamassok, //是否累积 1是 2否
          mkdapt: this.vouInfo.mkdapt, //套餐适用人群（1：全部用户  2：新用户  3：老用户）
          mkinstruc: this.vouInfo.mkinstruc, //须知
          mkeffe: this.vouInfo.mkeffe, //有效期（1：永久  2：固定时段  3：购买天数
          mkstime: _st, //固定时段开始时间
          mketime: _et, //固定时段结束时间
          mkdays: this.vouInfo.mkdays,  //购买天数
          mkmark: this.vouInfo.mkmark,  //内容
          mkamassro: this.vouInfo.mkamassro, //每人 / 每次
          mkamassno: this.vouInfo.mkamassno //张数
        }
        if (this.serveInfoEdit === null) { // 新增提交
          markkimAdd(params).then(res => {
            if (res.status === 1) {
              this.$alert('新增代金券基本信息成功，请尽快完善“适用门店” “适用服务”信息', '保存成功', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$emit('formSuccess')
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else {  // 编辑提交
          markkimUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.zdyLabel{
  margin-bottom: 8px;
  margin-right: 10px;
  width: 40%;
}
</style>
