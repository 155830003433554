<template>
  <!-- 代金券匹配服务 -->
  <div class="box100">
    <!-- 一级列表 -->
    <div v-if="!showform">
      <div class="settingAdd serveadd" @click="handleAdd"><span>添加服务</span></div>
      <div class="table_list">
        <el-table
          :data="serOneList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="序号"
            type="index"
            width="100">
          </el-table-column>
          <el-table-column
            label="服务名称"
            prop="servename">
          </el-table-column>
          <el-table-column
            label="服务分组"
            prop="sgname_one,sgname_two">
            <template slot-scope="scope">
              <span>{{scope.row.sgname_one}}/{{scope.row.sgname_two}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ishelf"
            label="上架状态">
            <template slot-scope="scope">
              <span v-if="scope.row.ishelf == true" >上架</span>
              <span v-if="scope.row.ishelf == false">下架</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleRemove(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
      <!--  提交按钮  -->
      <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
        <div class="settingCancel" @click="infoCancel">返回</div>
        <!-- <div class="settingConfirm" @click="infoConfirm">确定</div> -->
      </div>
    </div>
    <!-- 二级列表 -->
    <div v-if="showform">
      <div class="table_list">
        <el-table
          ref="shopTable"
          :data="serTwoList"
          :header-cell-style="{background:'#EBF2FE'}"
          :row-key="getKeys"
          @row-click="handleRowClick"
          @selection-change="selectionChange">
          <el-table-column
            prop="mkaid"
            type="selection"
            :reserve-selection="true">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="100">
          </el-table-column>
          <el-table-column
            label="服务名称"
            prop="servename">
          </el-table-column>
          <el-table-column
            label="服务分组"
            prop="sgname_one,sgname_two">
            <template slot-scope="scope">
              <span>{{scope.row.sgname_one}}/{{scope.row.sgname_two}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ishelf"
            label="上架状态">
            <template slot-scope="scope">
              <span v-if="scope.row.ishelf == true" >上架</span>
              <span v-if="scope.row.ishelf == false">下架</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange1"
        :current-page="page1"
        :page-sizes="[10]"
        :page-size="size1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total1"
        class="settingPage">
      </el-pagination>

      <!--  提交按钮  -->
      <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
        <div class="settingCancel" @click="goCancel">取消</div>
        <div class="settingConfirm" @click="goConfirm">添加</div>
      </div>
    </div>
  </div>
</template>

<script>
import { markkimServe, markkimSerDelete, markkimSerGet, markkimSerAdd } from '@/api/market.js'
export default {
  props: ['serveInfoEdit'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      serOneList: [], //一级列表
      multipleSelection: [],
      showform: false,
      page1: 1,
      size1: 10,
      total1: 0,
      serTwoList: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    // 匹配服务查询
    init () {
      const params = {
        mkid: this.serveInfoEdit.mkid,
        page_index: this.page,
        page_size: this.size
      }
      markkimServe(params).then(res => {
        if (res.status === 1) {
          this.serOneList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.serOneList = []
          this.total = 0
        }
      })
    },
    // 添加服务列表查询
    getInit () {
      const params = {
        page_index: this.page1,
        page_size: this.size1,
        mkid: this.serveInfoEdit.mkid
      }
      markkimSerGet(params).then(res => {
        if (res.status === 1) {
          this.serTwoList = res.data.data
          this.total1 = res.data.recordcount
        } else {
          this.serTwoList = []
          this.total1 = 0
        }
      })
    },
    getKeys (row) {
      return row.mkid
    },
    handleRowClick (row, column, event) {
      this.$refs.shopTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) {
        this.multipleSelection = []
      } else {
        this.multipleSelection = val
      }
    },
    // 返回
    infoCancel () {
      this.$emit('serCancel')
    },
    // 添加服务
    handleAdd () {
      this.showform = true
      this.getInit()
    },
    // 移除
    handleRemove (row) {
      this.$confirm('当前匹配服务是否移除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markkimSerDelete({ mkasid: row.mkasid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 第二层列表
    // 返回
    goCancel () {
      this.showform = false
      this.init()
    },
    // 确定
    goConfirm () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择服务')
      } else {
        const arr = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push(this.multipleSelection[i].serveid)
        }
        this.$confirm('请确认您是否要对以上所选的服务进行添加', '添加服务', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          markkimSerAdd({ serveid: arr, mkid: this.serveInfoEdit.mkid }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
              this.showform = false
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    pageChange1 (page) {
      this.page1 = page
      this.getInit()
    }
  }
}
</script>

<style scoped>
.serveadd {
  margin-bottom: 20px;
}
</style>
