<template>
  <!-- 代金券 表单-->
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="serveFormType === '0' ? 'sfActive': ''" @click="changeForm('0')">基本信息</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '1' ? 'sfActive': ''" @click="changeForm('1')">适用门店</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '2' ? 'sfActive': ''" @click="changeForm('2')">匹配服务</div>
    </div>
    <div class="specialFR">
      <!-- 基本信息 -->
      <div class="formItemType" v-if="serveFormType === '0'">
        <VouInfo :serveInfoEdit="serveInfoEdit" @HandCancel="HandCancel" @formSuccess="formSuccess"></VouInfo>
      </div>

      <!-- 适用门店 -->
      <div class="formItemType" v-if="serveFormType === '1'">
        <div class="table_list">
          <el-table
            ref="shopTable"
            :data="shopList"
            :header-cell-style="{background:'#EBF2FE'}"
            :row-key="getRowKeys"
            @row-click="handleRowClick"
            @selection-change="selectionChange">
            <el-table-column
              prop="mkid"
              type="selection">
            </el-table-column>
            <el-table-column
              label="门店编号"
              type="index"
              width="100">
            </el-table-column>
            <el-table-column
              label="区域"
              prop="areas_name">
            </el-table-column>
            <el-table-column
              label="门店名称"
              prop="shopname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="负责人"
              prop="shopuser">
            </el-table-column>
            <el-table-column
              label="联系电话"
              prop="superphone">
            </el-table-column>
            <el-table-column
              label="门店类型"
              prop="shoptype">
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>

        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="chanCancel">取消</div>
          <div class="settingConfirm" @click="handSetConfirm">确定</div>
        </div>
      </div>

      <!-- 匹配服务 -->
      <div class="formItemType" v-if="serveFormType === '2'">
        <VouServe :serveInfoEdit="serveInfoEdit" @serCancel="serCancel" @serSuccess="serSuccess"></VouServe>
      </div>
    </div>
  </div>
</template>

<script>
import VouInfo from '@/components/marketing/vouInfo'
import VouServe from '@/components/marketing/vouServe'
import { markkimShopGet, markkimShopID } from '@/api/market.js'
export default {
  props: ['serveInfoEdit'],
  components: {
    VouInfo,
    VouServe
  },
  data () {
    return {
      serveFormType: '0',
      page: 1,
      size: 10,
      total: 0,
      shopList: [],
      multipleSelection: [],
    }
  },
  created () {
  },
  methods: {
    changeForm (type) {
      this.serveFormType = type
      if (type === '1') { // 适用门店
        if (this.serveInfoEdit !== null) {
          this.init()
        }
      }
    },
    // 适用门店
    init () {
      const params = {
        mkid: this.serveInfoEdit.mkid,
        page_index: this.page,
        page_size: this.size
      }
      markkimShopGet(params).then(res => {
        if (res.status === 1) {
          this.shopList = res.data.data
          this.total = res.data.recordcount
           this.$nextTick(() => {
            for (let i = 0; i < this.shopList.length; i++) {
              if (this.shopList[i].ischoose === 1) {
                this.$refs.shopTable.toggleRowSelection(this.shopList[i])
              }
            }
          })
        } else {
          this.shopList = []
          this.total = 0
        }
      })
    },
    getRowKeys (row) {
      return row.mkid
    },
    handleRowClick (row, column, event) {
      this.$refs.shopTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) {
        this.multipleSelection = []
      } else {
        this.multipleSelection = val
      }
    },
    // 适用门店 确定
    handSetConfirm () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择门店')
      } else {
        const arr = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push(this.multipleSelection[i].shopid)
        }
        this.$confirm('确认是否绑定该门店', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          markkimShopID({ shopid: arr, mkid: this.serveInfoEdit.mkid }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    // 适用门店 取消
    chanCancel () {
      this.$emit('formCancel')
    },
    // 基本信息取消
    HandCancel () {
      this.$emit('formCancel')
    },
    // 匹配服务取消
    serCancel () {
      this.$emit('formCancel')
    },
    // 匹配服务确定
    serSuccess () {
      this.$emit('formSuccess')
    },
    // 基本信息确定
    formSuccess () {
      this.$emit('formSuccess')
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
  }
}
</script>

<style scoped>

</style>
