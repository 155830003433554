<template>
  <!-- 代金券 -->
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
      </template>
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="mkname"  placeholder="请输入抵扣券名称" class="inputSearch"></el-input>
        <el-select v-model="mkstate" placeholder="状态" class="retSelect">
          <el-option v-for="item in mkstateList" :key="item.index" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="rebtList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="抵扣券名称"
            prop="mkname">
          </el-table-column>
          <el-table-column
            label="发放方式"
            prop="mkpayway">
            <template slot-scope="scope">
              <span v-if="scope.row.mkpayway == 1" >平台发放</span>
              <span v-if="scope.row.mkpayway == 2" >线下发放</span>
            </template>
          </el-table-column>
          <el-table-column
            label="优惠内容"
            prop="mkmark"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="mkcount"
            label="已使用/剩余">
            <template slot-scope="scope">
              <span>{{scope.row.isnonum}}/{{scope.row.issnum}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="适用门店"
            prop="shop_count">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="mkstate">
            <template slot-scope="scope">
              <span v-if="scope.row.mkstate == 1" >已上架</span>
              <span v-if="scope.row.mkstate == 2" >已下架</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
          label="操作"
          width="200">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[4].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handDown(scope.row)">下载</el-button>
            </template>
            <template v-if="roleBtn[3].power">
              <el-button type="text" style="color:#44B557" size="small" @click="handishelf(scope.row)"  v-if="scope.row.mkstate == 2">上架</el-button>
              <el-button type="text" style="color:#F44C8E" size="small" @click="handishelf(scope.row)"  v-if="scope.row.mkstate == 1">下架</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <div class="box100" v-if="showForm">
      <VouForm :serveInfoEdit="serveInfoEdit" @formCancel="formCancel" @formSuccess="formSuccess"></VouForm>
    </div>
  </div>
</template>

<script>
import VouForm from '@/components/marketing/vouForm'
import { markkimGet, markkimDelete, markkimOper } from '@/api/market.js'
export default {
  components: {
    VouForm
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      rebtList: [],
      mkname: '',
      mkstate: '',
      mkstateList: [{ label: '全部 ', value: 0 }, { label: '上架 ', value: 1 }, { label: '下架', value: 2 }],
      showForm: false,
      serveInfoEdit: {},
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        mkstate: this.mkstate,
        mkname: this.mkname
      }
      markkimGet(params).then(res => {
        if (res.status === 1) {
          this.rebtList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.rebtList = []
          this.total = 0
        }
      })
    },
     // 取消表单提交
    formCancel () {
      this.showForm = false
    },
    // 表单提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 点击新增
    handleAdd () {
      this.showForm = true
      this.serveInfoEdit = null
    },
    // 编辑
    handEdit (row) {
      this.showForm = true
      this.serveInfoEdit = {...row}
    },
    // 代金券编号下载
    handDown (row) {
      window.location.href = row.mkfile
    },
    // 上架  下架
    handishelf (row) {
      this.$confirm(`确定执行${!row.mkstate ? "上架" : "下架"}操作, 是否继续？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markkimOper({ mkid: row.mkid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该服务, 是否继续?', '删除服务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markkimDelete({ mkid: row.mkid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.mkname = ''
      this.mkstate = ''
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
